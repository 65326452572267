<template>
    <div class="con-wrap">
        <CarrotTitle title="자산관리 수정">
            - 회사에서 보유중인 자산의 경우 “보유” 상태, 사용자에게 지급된 자산은 “지급” 상태로 등록해 주세요.<br>
            - 사용자에게 지급된 자산의 경우, 해당 사용자를 반드시 지정해 주세요.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>자산 번호</th>
                                <td>
                                    {{ mod.info.code }}
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 분류</th>
                                <td>{{mod.info.cat1}} / {{mod.info.cat2}}</td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 모델명</th>
                                <td>
                                  <input type="text" class="w-100per" maxlength="50" v-model.trim="mod.info.model">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 시리얼</th>
                                <td>{{mod.info.serialno}}</td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 취득단가</th>
                                <td>{{mod.info.price}}원</td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 구입일</th>
                                <td>{{mod.info.buy_date}}
                                </td>
                            </tr>
                            <tr>
                              <th><span class="txt-red">*</span> 지급일</th>
                              <td>{{mod.info.gifted_date}}
                              </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 용도</th>
                                <td>
                                    <label><input type="radio" id="usage1" name="usage" value="개인용" v-model="mod.info.usage"><span class="ml-5 mr-20"> 개인용</span></label>
                                    <label><input type="radio" id="usage2" name="usage" value="부서용" v-model="mod.info.usage"><span class="ml-5 mr-20"> 부서용</span></label>
                                    <label><input type="radio" id="usage3" name="usage" value="대여용" v-model="mod.info.usage"><span class="ml-5 mr-20"> 대여용</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 상태</th>
                                <td>
                                    <label><input type="radio" id="state1" name="state" value="지급" v-model="mod.info.state"><span class="ml-5 mr-20"> 지급</span></label>
                                    <label><input type="radio" id="state2" name="state" value="보유" v-model="mod.info.state"><span class="ml-5 mr-20"> 보유</span></label>
                                    <label><input type="radio" id="state3" name="state" value="폐기요청" v-model="mod.info.state"><span class="ml-5 mr-20"> 폐기요청</span></label>
                                    <label><input disabled type="radio" id="state4" name="state" value="폐기완료" v-model="mod.info.state"><span class="ml-5 mr-20"> 폐기완료</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>사용자</th>
                                <td>
                                    <carrot-sector  v-if="mod.isLoaded==true"  v-model="mod.info.idx_sector"  @change="mod.doSearchTeam" class="w-200px"></carrot-sector>
                                    <carrot-dept v-if="mod.isLoaded==true"  v-model:idx_sector="mod.info.idx_sector" v-model="mod.info.idx_office" @change="mod.doSearchTeam" class="w-200px"></carrot-dept>
                                    <carrot-team v-if="mod.isLoaded==true" v-model:idx_office="mod.info.idx_office" v-model="mod.info.idx_team" class="w-200px ml-10"></carrot-team>
                                    <carrot-staff v-if="mod.isLoaded==true" v-model:idx_office="mod.info.idx_office" v-model:idx_team="mod.info.idx_team" v-model="mod.info.idx_hq" class="w-200px ml-10"></carrot-staff>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 비고<br>(변경사항)</th>
                                <td>
                                    <textarea class="w-100per h-100px" v-model.trim="mod.info.etc_memo" maxlength="500"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn-default float-left mt-30" @click="mod.goList">목록</button>
                    <!--<button class="btn-default float-left mt-30 ml-10" @click="mod.doDelete">삭제</button>-->
                    <button v-if=" [ '124', '19814' ].indexOf(String(store.state.idx_staff)) != -1" class="btn-default float-right mt-30" @click="mod.doSubmit">수정</button>
                    <div class="clear"></div>
                  <h3 class="block-title mt-40 mb-20">자산 운용 현황</h3>

                  <table class="table-row">
                    <colgroup>
                      <col width="180">
                      <col width="*">
                      <col width="180">
                      <col width="*">
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>등록일</th>
                        <td>{{ mod.info.createdate }}</td>
                        <th>등록자</th>
                        <td>{{ mod.info.regist_name }}</td>
                      </tr>
                      <tr>
                        <th>반출일</th>
                        <td>{{ mod.info.gifted_date }}</td>
                        <th>반출자</th>
                        <td>{{ mod.info.gifted_name }}</td>
                      </tr>
                      <tr>
                        <th>수령일</th>
                        <td>{{mod.info.receipt_date}}</td>
                        <th>수령자</th>
                        <td>{{ mod.info.receipt_name }}</td>
                      </tr>
                      <tr>
                        <th>회수일(퇴사일)</th>
                        <td>{{ mod.info.resignationday }}</td>
                        <th>회수자</th>
                        <td>{{ mod.info.receipt_name }}</td>
                      </tr>
                    </tbody>
                  </table>

                    <h3 class="block-title mt-40 mb-20" >변경이력</h3>
                    <table class="table-col">
                        <colgroup>
                            <col width="*">
                            <col width="120">
                            <col width="200">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>변경사항</th>
                                <th>변경일</th>
                                <th>처리자</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in history.list" :key="i">
                                <td v-html="irow.memo"></td>
                                <td>{{ irow.wdate }}</td>
                                <td>{{ irow.ename + '(' + irow.kname + ')' }}</td>
                            </tr>
                            <tr v-if="history.total==0">
                                <td colspan="3">변경 이력이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                    <h3 class="block-title mt-40 mb-20" v-if="mod.info.usage=='대여용'">대여이력</h3>
                    <table class="table-col" v-if="mod.info.usage=='대여용'">
                        <colgroup>
                            <col width="*">
                            <col width="120">
                            <col width="120">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>대여자</th>
                                <th>대여일</th>
                                <th>반납일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in rental.list" :key="i">
                                <td>{{ irow.ename + '(' + irow.kname + ')' }}</td>
                                <td>{{ irow.rental_date }}</td>
                                <td>{{ irow.return_date }}</td>
                            </tr>
                            <tr v-if="rental.total==0">
                                <td colspan="3">대여 이력이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import CarrotStaff from '@/components/common/CarrotStaff.vue'
import CarrotSector from '@/components/common/CarrotSector.vue'
import {useStore} from "vuex";

export default {
    layout:"myPIMS",
    components: {
        CarrotDept,
        CarrotTeam,
        CarrotStaff,
        CarrotSector
    },
    setup() {
        const router = useRouter();
        const route  = useRoute();
        const toast  = useToast();
        const store  = useStore();

        const history = reactive({
            list : [], total : 0,

            doSearch : () => {
                let params = {
                    idx : mod.idx
                };

                axios.get("/rest/mypims/getAssetsHistory", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        history.list  = res.data.list;
                        history.total = res.data.total;
                    } else {
                        console.info(res);
                    }
                });
            }
        });

        const rental = reactive({
            list : [], total : 0,

            doSearch : () => {
                let params = {
                    idx : mod.idx
                };

                axios.get("/rest/mypims/getAssetsRentalHistory", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        rental.list  = res.data.list;
                        rental.total = res.data.total;
                    } else {
                        console.info(res);
                    }
                });
            }
        });

        const mod = reactive({
            isLoaded : false,
            idx  : 0,
            info : { cat1:"", cat2:"" },

            categories : [],
            hq_office  : [],
            hq_team    : [],
            hq_list    : [],

            cat1 : "", cat2 : "",

            setCat1 : () =>{
                mod.info.cat2 = "";
            },

            getCategory : () => {
                axios.get("/json/rental_category.json", {}).then((res) => {
                    if( res.status == 200 ){
                        mod.categories = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            doSearchOffice : () => {
                let params = {};
                axios.get("/rest/mypims/getHQOfficeList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        mod.hq_office = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            doSearchTeam : () => {
                let params = {
                    idx_office : mod.info.idx_office
                };
                axios.get("/rest/mypims/getHQTeamList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        mod.hq_team = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            doSearchHQ : () => {
                let params = {
                    idx_office : mod.info.idx_office,
                    idx_team     : mod.info.idx_team
                };
                axios.get("/rest/mypims/getHQList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        mod.hq_list = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            doSearch : () => {
                let params = {
                    idx : mod.idx
                };
                axios.get("/rest/mypims/getAssetsInfo", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        mod.info = res.data;

                        for(let i=0; i<mod.categories.length; i++){
                            let irow = mod.categories[i];

                            if( irow.name == mod.info.cat1 ){
                                for(let j=0; j<irow.children.length; j++){
                                    let jrow = irow.children[j];
                                    console.log(jrow, mod.info.cat2, jrow == mod.info.cat2);

                                    if( jrow == mod.info.cat2 ){
                                        mod.cat1 = irow;
                                        mod.cat2 = jrow;

                                        console.log(mod.cat1, mod.cat2);
                                        break;
                                    }
                                }
                                break;
                            }
                        }

                        if( mod.info.state == '대여용' ){
                            rental.doSearch();
                        } else {
                            history.doSearch();
                        }

                        mod.isLoaded = true;

                        // mod.doSearchOffice();
                        // mod.doSearchTeam();
                        // mod.doSearchHQ();
                    } else {
                        console.info(res);
                    }
                });
            },

            doSubmit : () => {
                if( [ '124', '19814' ].indexOf(String(store.state.idx_staff)) == -1){// 수정권한 ben, louis
                  alert("수정권한이 없습니다")
                  return;
                }
                let params = {
                    idx      : mod.idx,
                    model    : mod.info.model,
                    usage    : mod.info.usage,
                    state    : mod.info.state,
                    idx_sector : mod.info.idx_sector,
                    idx_office : mod.info.idx_office,
                    idx_team   : mod.info.idx_team,
                    idx_hq     : mod.info.idx_hq,
                    etc_memo : mod.info.etc_memo
                };


                if(params.etc_memo == null || params.etc_memo == ""){
                  // alert("중분류를 선택하세요.");
                  alert("필수 입력 항목(비고)을 작성하세요.");
                  return;
                }

                if( params.model == "" ){
                    // alert("모델명을 입력하세요.");
                    alert("필수 입력 항목(모델명)을 작성하세요. ");
                    return;
                }

                if( params.usage == "" ){
                    // alert("용도를 선택하세요.");
                    alert("필수 입력 항목(용도)을 작성하세요.");
                    return;
                }
                if( params.state == "" ){
                    // alert("상태를 선택하세요.");
                    alert("필수 입력 항목(상태)을 작성하세요.");
                    return;
                }

                // 한/영, 숫자, 특수문자 500자 이내
                if( params.etc_memo.length >= 500 ){
                    alert("500자 이하로 입력하세요.");
                    return;
                }

                axios.post("/rest/mypims/modAssets", params).then((res) => {
                    if( res.data.err == 0 ){
                        alert("자산을 수정 하였습니다.");
                        mod.doSearch();
                    } else {
                        console.info(res);
                    }
                });
            },

            doDelete : () => {
                if( mod.info.state != '폐기' ) {
                    alert("폐기상태로 수정해주세요.");
                    return;
                }
                if( confirm("삭제하시겠습니까?") == true ){
                    let params = {
                        idx : mod.idx
                    };
                    axios.post("/rest/mypims/delAssets", params).then((res) => {
                        if( res.data.err == 0 ){
                            alert("자산을 삭제 하였습니다.");
                            router.push({
                                name   : 'myPIMS-AssetManagementList',
                                params : route.params
                            });
                        } else {
                            if( res.data.err_msg ) toast.error(res.data.err_msg);
                        }
                    });
                }
            },

            goList : () => {
                if( confirm("수정한 내용이 저장되지 않습니다. 목록으로 이동하시겠습니까?") == true ){
                    // router.go(-1);
                    router.push({
                        name   : 'myPIMS-AssetManagementList',
                        params : route.params
                    });
                }
            }
        });

        onMounted(() => {
            mod.getCategory();
            mod.doSearchOffice();

            mod.idx = route.params.idx;
            if ( !mod.idx ) {
                console.log('a');
            }

            setTimeout(function(){
                mod.doSearch();
            }, 200);

        });

        onUnmounted(() => {

        });

        return { mod, history, rental,store };
    }
}
</script>

<style lang="scss" scoped>
</style>